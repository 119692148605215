var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "gift-content-anniversary",
    },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            {
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1000",
                "data-aos-once": "true",
              },
            },
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass: "text-center description-text",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          "margin-bottom": "5px",
                        },
                      },
                      [_vm._v("主委特別加碼")]
                    ),
                    _c("h1", [_vm._v("🌟幸運抽獎許願池🌟")]),
                    _c("p", [
                      _vm._v("除了原本的「好禮第1重」獎項照舊抽出"),
                      _c("br", {
                        staticClass: "pc-br",
                      }),
                      _vm._v("於2022/12/9 ~ 2022/1/18活動期間"),
                      _c("br", {
                        staticClass: "pc-br",
                      }),
                      _vm._v("推薦滿"),
                      _c(
                        "span",
                        {
                          staticClass: "highlight",
                        },
                        [_vm._v("5張")]
                      ),
                      _vm._v("抽獎券，立即擁有下述獎池資格"),
                      _c("br", {
                        staticClass: "pc-br",
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                        },
                        [_vm._v("週年慶「主委加碼特別獎」")]
                      ),
                      _c("br", {
                        staticClass: "pc-br",
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "highlight",
                        },
                        [_vm._v("iPhone14、Switch、Apple Watch SE")]
                      ),
                      _c("br", {
                        staticClass: "pc-br",
                      }),
                      _vm._v("將會依據推薦人數上升，累計抽出獎項！"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "mb-0",
                      },
                      [
                        _vm._v("只要親友透過"),
                        _c(
                          "span",
                          {
                            staticClass: "highlight",
                          },
                          [_vm._v("你的專屬推薦頁面")]
                        ),
                        _vm._v("預約試聽，"),
                        _c("br", {
                          staticClass: "pc-br",
                        }),
                        _vm._v("即可獲得抽獎券！"),
                        _c("br", {
                          staticClass: "pc-br",
                        }),
                        _vm._v("累積越多抽獎券，不但獎項越"),
                        _c(
                          "span",
                          {
                            staticClass: "highlight",
                          },
                          [_vm._v("豐富")]
                        ),
                        _c("br", {
                          staticClass: "pc-br",
                        }),
                        _vm._v("中獎機率也越高噢！"),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass: "text-center row-margin",
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1000",
                "data-aos-once": "true",
              },
            },
            [_vm._v("如何得到抽獎券？")]
          ),
          _c(
            "b-row",
            {
              staticClass: "row-margin",
            },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "data-aos": "fade-up-right",
                    "data-aos-duration": "1000",
                    "data-aos-once": "true",
                    cols: "12",
                    md: "6",
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "6",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "imgBox color_1 ticket",
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: "https://cdn2.wuwow.tw/pictures/mgms/ticket-two.png",
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _vm._v("推薦第1位"),
                                _c("br"),
                                _vm._v("獲得2張抽獎券"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "6",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "imgBox color_1 ticket",
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: "https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png",
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _vm._v("推薦第2~9位"),
                                _c("br"),
                                _vm._v("獲得1張抽獎券"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "6",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "imgBox color_1 ticket",
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: "https://cdn2.wuwow.tw/pictures/mgms/ticket-three.png",
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _vm._v("推薦第10位"),
                                _c("br"),
                                _vm._v("獲得3張抽獎券"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "6",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "imgBox color_1 ticket",
                            },
                            [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: "https://cdn2.wuwow.tw/pictures/mgms/ticket-one.png",
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _vm._v("推薦第11位以上"),
                                _c("br"),
                                _vm._v("獲得1張抽獎券"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  attrs: {
                    "data-aos": "fade-up-left",
                    "data-aos-duration": "1000",
                    "data-aos-once": "true",
                    cols: "12",
                    md: "6",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ticket-caption",
                    },
                    [
                      _c("h4", [
                        _c(
                          "span",
                          {
                            staticClass: "highlight",
                          },
                          [_vm._v("推薦越多，得獎機率越高！")]
                        ),
                      ]),
                      _c("p", [
                        _vm._v("◆ 推薦第1位朋友，"),
                        _c("br", {
                          staticClass: "m-br",
                        }),
                        _vm._v("獲得2張抽獎券"),
                      ]),
                      _c("p", [
                        _vm._v("◆ 推薦第2-9位朋友，"),
                        _c("br", {
                          staticClass: "m-br",
                        }),
                        _vm._v("獲得1張抽獎券"),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "ps-text",
                        },
                        [
                          _vm._v(
                            "Ps. 當推薦第4位朋友，總共累計獲得5張抽獎券就能擁有參加「主委加碼獎池」的資格囉"
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v("◆ 推薦第10位朋友，"),
                        _c("br", {
                          staticClass: "m-br",
                        }),
                        _vm._v("獲得3張抽獎券"),
                      ]),
                      _c("p", [
                        _vm._v("◆ 推薦第11位(含)以上朋友，"),
                        _c("br", {
                          staticClass: "m-br",
                        }),
                        _vm._v("各將獲得1張抽獎券"),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "text-center my-4",
                        },
                        [
                          _vm._v(
                            "例：小wu在同一個月推薦了13位朋友，所以小wu可以在當月拿到16張抽獎券呦！"
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass: "text-center row-margin",
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1000",
                "data-aos-once": "true",
              },
            },
            [_vm._v("抽獎許願池怎麼玩？")]
          ),
          _c(
            "b-row",
            {
              staticClass: "row-margin",
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-center",
                  attrs: {
                    "data-aos": "fade-up",
                    "data-aos-duration": "1000",
                    "data-aos-once": "true",
                    cols: "12",
                    md: "12",
                  },
                },
                [
                  _c("p", [
                    _vm._v("根據WUWOW在"),
                    _c(
                      "span",
                      {
                        staticClass: "highlight",
                      },
                      [
                        _vm._v(
                          "2022/12/9-2023/1/18 累計發送給全體學員的抽獎券數量"
                        ),
                      ]
                    ),
                    _vm._v("，抽出相對應的獎項，"),
                    _c("br", {
                      staticClass: "m-br1",
                    }),
                    _vm._v(
                      "累積越多，好禮越大，快點樓頂揪樓下，旁邊揪旁邊，一起WU朋引伴作伙來吧！"
                    ),
                  ]),
                ]
              ),
              _vm._l(_vm.giftImgGroup, function (img, index) {
                return _c(
                  "b-col",
                  {
                    key: img.role_no,
                    attrs: {
                      "data-aos": "fade-up",
                      "data-aos-duration": "1000",
                      "data-aos-delay": "500",
                      "data-aos-once": "true",
                      cols: "6",
                      md: "4",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "imgBox",
                        class: index % 2 == 0 ? "color_1" : "color_2",
                      },
                      [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: {
                            src: img.uri_path,
                          },
                        }),
                      ]
                    ),
                    _c("p", {
                      staticClass: "gift",
                      domProps: {
                        innerHTML: _vm._s(img.prize_name),
                      },
                    }),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "b-row",
            {
              staticClass: "row-margin",
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1000",
                "data-aos-delay": "1000",
                "data-aos-once": "true",
              },
            },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    cols: "12",
                    md: "12",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "rule-caption",
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass: "text-center",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "highlight",
                            },
                            [_vm._v("推薦越多，得獎機率越高！")]
                          ),
                        ]
                      ),
                      _c("p", [
                        _vm._v(
                          "◆ 當月WUWOW Jr 發送滿70張抽獎券，將加抽出 Apple Watch SE"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "◆ 當月WUWOW Jr 發送滿150張抽獎券，將加抽出 Apple Watch SE + Switch"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "◆ 當月WUWOW Jr 發送滿200張抽獎券，將加抽出 Apple Watch SE + Switch + iPhone 14"
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "example-text text-center",
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1500",
                "data-aos-delay": "500",
                "data-aos-once": "true",
              },
            },
            [
              _c("img", {
                staticClass: "switch-img",
                attrs: {
                  src: "https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-switch.png",
                  alt: "",
                },
              }),
              _c("img", {
                staticClass: "iphone-img",
                attrs: {
                  src: "https://cdn2.wuwow.tw/pictures/mgms/anniversary-2022-iphone-watch.png",
                  alt: "",
                },
              }),
              _vm._v("例：當月 WUWOW Jr 發送給全體學員200張抽獎券，"),
              _c("br"),
              _vm._v(
                "那麼在這個月將會抽出Apple Watch SE + Switch + iPhone14 共三位幸運兒喲！"
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "rightNowBox anniversary-right-now-box text-center pb-0",
              attrs: {
                "data-aos": "fade-up",
                "data-aos-duration": "1500",
                "data-aos-delay": "500",
                "data-aos-once": "true",
              },
            },
            [
              _c("h4", [_vm._v("你還在等什麼？")]),
              _c(
                "div",
                {
                  staticClass: "rightNowBtn",
                  attrs: {
                    id: "rightNowBtn2",
                    "data-aos": "zoom-in",
                    "data-aos-duration": "500",
                    "data-aos-delay": "1000",
                    "data-aos-once": "true",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.mgmRecommendUrl,
                        target: "_blank",
                      },
                    },
                    [_c("span", [_vm._v("立刻開揪")])]
                  ),
                ]
              ),
              _c("p", [_vm._v("快與朋友一起體驗最有效的線上英文學習囉！")]),
              _c(
                "div",
                {
                  staticClass: "shareContent w-100 px-4 p",
                },
                [
                  _c("customizedsharetext", {
                    attrs: {
                      usePage: "giftContentAnniversary",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }