var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticClass: "mb-2",
        },
        [
          _c(
            "b-col",
            {
              attrs: {
                "align-self": "start",
              },
            },
            [
              _c(
                "b-carousel",
                {
                  staticStyle: {
                    "text-shadow": "1px 1px 2px #333",
                  },
                  attrs: {
                    id: "carousel-1",
                    interval: 4000,
                    controls: "",
                    indicators: "",
                    background: "white",
                  },
                  model: {
                    value: _vm.slide,
                    callback: function ($$v) {
                      _vm.slide = $$v
                    },
                    expression: "slide",
                  },
                },
                [
                  !_vm.deadlinesAfter
                    ? _c("b-carousel-slide", {
                        attrs: {
                          "img-src": require("@/assets/mgm/anniversary_2023.svg"),
                          fluid: "",
                        },
                      })
                    : _vm._e(),
                  _vm
                    .moment()
                    .isBetween("2024-04-01 00:00:00", "2024-04-30 00:00:00")
                    ? _c("b-carousel-slide", {
                        attrs: {
                          "img-src":
                            "https://cdn2.wuwow.tw/arts/wuwow-nuxt3/2024-children-day/mgm-carousel-children-day.svg",
                          fluid: "",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.scrollToMethodPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("b-carousel-slide", {
                        attrs: {
                          "img-src": require("@/assets/mgm/summerCamp-Deal.jpg"),
                          fluid: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src":
                        "https://cdn2.wuwow.tw/pictures/mgms/mgm-banner-cover.jpg",
                      fluid: "",
                    },
                  }),
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src":
                        "https://cdn2.wuwow.tw/pictures/mgms/mgm-banner-1-2.jpg",
                      fluid: "",
                    },
                  }),
                  _c("b-carousel-slide", {
                    attrs: {
                      "img-src": require("@/assets/mgm/summerCamp-step2.jpg"),
                      fluid: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "leftSideBox",
              attrs: {
                md: "3",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "shareContent shareContent-left text-center pt-5",
                },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("img", {
                          staticClass: "anniversary-2022-left-ad mx-auto",
                          attrs: {
                            src: "https://cdn2.wuwow.tw/pictures/mgms/encode.svg",
                            alt: "現在開始邀請好友",
                            title: "現在開始邀請好友",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("img", {
                          staticClass: "mx-auto top-row",
                          attrs: {
                            src: "https://cdn2.wuwow.tw/pictures/mgms/customized-share-arrow.svg",
                            alt: "現在開始邀請好友",
                            title: "現在開始邀請好友",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm.vipRecommendInfo.is_buy_google && !_vm.deadlinesAfter
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mt-4 position-relative",
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "sidebar-subTitle",
                                },
                                [_vm._v("Google 專屬團報")]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.mgmRecommendGoogleUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src: "https://cdn2.wuwow.tw/pictures/mgms/google-2023.png",
                                      alt: "現在開始邀請好友",
                                      title: "現在開始邀請好友",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "my-4 position-relative",
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "sidebar-subTitle",
                            },
                            [_vm._v("好友推薦")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.mgmRecommendUrl,
                                target: "_blank",
                              },
                            },
                            [
                              _vm.deadlines
                                ? _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src: "https://cdn2.wuwow.tw/pictures/mgms/mgm-link-normal.svg",
                                      alt: "現在開始邀請好友",
                                      title: "現在開始邀請好友",
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src: "https://cdn2.wuwow.tw/pictures/mgms/sidebar-2023-banner.svg",
                                      alt: "現在開始邀請好友",
                                      title: "現在開始邀請好友",
                                    },
                                  }),
                            ]
                          ),
                          _c("img", {
                            staticClass: "star-icon star-icon-1",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-1.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "star-icon star-icon-2",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-2.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "star-icon star-icon-3",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-3.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "star-icon star-icon-4",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-4.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "star-icon star-icon-5",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-5.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "star-icon star-icon-6",
                            attrs: {
                              src: "https://cdn2.wuwow.tw/pictures/mgms/icon/star-icon-6.svg",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("customizedsharetext", {
                    attrs: {
                      usePage: "leftSideBar",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "btnGroup",
                  attrs: {
                    align: "center",
                  },
                },
                _vm._l(_vm.btnGroup, function (btn) {
                  return _c(
                    "button",
                    {
                      key: btn.value,
                      staticClass: "selectContentBtn",
                      class: [
                        _vm.showContent == btn.value ? "btnSelected" : "",
                      ],
                      on: {
                        click: function ($event) {
                          _vm.showContent = btn.value
                        },
                      },
                    },
                    [
                      _vm._v(_vm._s(btn.text)),
                      _c("i", {
                        staticClass: "fas fa-angle-down iconShow",
                      }),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "wishPondBox",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "wishPondBoxTitleBox",
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("wishPondBox")))]),
                      _vm._v(_vm._s(_vm.raffleGift[_vm.raffleGiftSlide].title)),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "carouselBox",
                    },
                    [
                      _c(
                        "b-carousel",
                        {
                          staticStyle: {
                            "text-shadow": "1px 1px 2px #333",
                          },
                          attrs: {
                            id: "carousel-2",
                            interval: 4000,
                            controls: "",
                            indicators: "",
                          },
                          model: {
                            value: _vm.raffleGiftSlide,
                            callback: function ($$v) {
                              _vm.raffleGiftSlide = $$v
                            },
                            expression: "raffleGiftSlide",
                          },
                        },
                        _vm._l(_vm.raffleGift, function (gift) {
                          return _c("b-carousel-slide", {
                            staticClass: "giftImg",
                            attrs: {
                              "img-src": gift.giftImg,
                              fluid: "",
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(
                        _vm._s(_vm.raffleGift[_vm.raffleGiftSlide].giftName)
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "wishPondBoxContentBox",
                    },
                    [
                      _c("hr", {
                        staticClass: "wishPondHr",
                      }),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.raffleGift[_vm.raffleGiftSlide].content
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "adContent",
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://wushare.tw/product/yotta-introduce-taiwan-in-english-sale-page/",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src: "https://cdn2.wuwow.tw/pictures/mgms/sidebar-2023-main.svg",
                          alt: "Wushare 廣告",
                          title: "Wushare 廣告",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "leftSideBox",
              attrs: {
                md: "9",
              },
            },
            [
              _c(
                "div",
                [
                  _c("recommendedrecords", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showContent === "records",
                        expression: "showContent === 'records'",
                      },
                    ],
                    attrs: {
                      vipRecommendInfo: _vm.vipRecommendInfo,
                    },
                  }),
                  _c("recommendedmethod", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showContent === "method",
                        expression: "showContent === 'method'",
                      },
                    ],
                    attrs: {
                      vipRecommendInfo: _vm.vipRecommendInfo,
                      mgmRecommendUrl: _vm.mgmRecommendUrl,
                      prizesConfig: _vm.vipRecommendInfo.mgm_prizes_config,
                    },
                  }),
                  _c("precautions", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showContent === "precaution",
                        expression: "showContent === 'precaution'",
                      },
                    ],
                    attrs: {
                      vipRecommendInfo: _vm.vipRecommendInfo,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("input", {
        staticStyle: {
          opacity: "0",
        },
        attrs: {
          id: "vipMgmUrl",
          type: "text",
        },
        domProps: {
          value: _vm.mgmRecommendUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }