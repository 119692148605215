var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "customized-share-text",
    },
    [
      _c(
        "div",
        {
          staticClass: "collapse-content",
          on: {
            click: function ($event) {
              _vm.collapseOpen = !_vm.collapseOpen
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "collapse-content-text",
            },
            [
              _c("span", [_vm._v("寫下感人心得，增加邀請成功率！")]),
              _c("br"),
              _vm.collapseOpen
                ? _c(
                    "span",
                    {
                      staticClass: "collapse-btn",
                    },
                    [_vm._v("忍痛收起")]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "collapse-btn",
                    },
                    [_vm._v("趕緊展開")]
                  ),
            ]
          ),
        ]
      ),
      _vm.usePage !== "leftSideBar" && _vm.collapseOpen
        ? _c(
            "div",
            {
              staticClass: "share-title d-flex flex-column align-items-center",
            },
            [
              _c(
                "div",
                {
                  staticClass: "title",
                },
                [_vm._v("我的心得分享")]
              ),
              _c(
                "span",
                {
                  staticClass: "my-2",
                },
                [_vm._v("寫下感人心得增加邀請成功率！")]
              ),
            ]
          )
        : _vm._e(),
      _vm.collapseOpen
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "share-text",
                class: _vm.focusShareText ? "share-text-focus" : "",
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.vipShareText,
                      expression: "vipShareText",
                    },
                  ],
                  attrs: {
                    name: "",
                    id: "",
                  },
                  domProps: {
                    value: _vm.vipShareText,
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.focusTextarea()
                    },
                    focusout: function ($event) {
                      return _vm.focusTextarea()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.vipShareText = $event.target.value
                    },
                  },
                }),
                _c("div", {
                  staticClass: "disable-text p-2",
                  domProps: {
                    innerHTML: _vm._s(_vm.defaultShareText),
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shareBtns",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mt-2 d-flex justify-content-end",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-warning btn-sm mr-2 text-dark",
                        attrs: {
                          id: `share-tooltip-target-${_vm.usePage}`,
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.copyShareText()
                          },
                          mouseout: function ($event) {
                            _vm.copiedShare = false
                          },
                        },
                      },
                      [
                        _vm._v("複製文字"),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              show: _vm.copiedShare,
                              target: `share-tooltip-target-${_vm.usePage}`,
                              triggers: "manual",
                            },
                          },
                          [_vm._v("複製成功")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning btn-sm",
                        attrs: {
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.saveShareText()
                          },
                        },
                      },
                      [_vm._v("儲存")]
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "shareBtnGroup px-0",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.usePage === "leftSideBar",
                  expression: "usePage === 'leftSideBar'",
                },
              ],
              staticClass: "copyUrl shareBtn",
              attrs: {
                id: `tooltip-target-${_vm.usePage}`,
              },
              on: {
                click: _vm.copyUrl,
                mouseout: function ($event) {
                  _vm.copiedMS = false
                },
              },
            },
            [
              _c(
                "b-tooltip",
                {
                  attrs: {
                    show: _vm.copiedMS,
                    target: `tooltip-target-${_vm.usePage}`,
                    triggers: "click",
                  },
                },
                [_vm._v("複製成功!!")]
              ),
              _vm._v("複製網址"),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "lineShare shareBtn mx-1",
              class:
                _vm.usePage === "giftContentAnniversary" && !_vm.collapseOpen
                  ? "d-none"
                  : "",
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.vipMediaShareUrl.lineLink,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://cdn2.wuwow.tw/pictures/socialites/lineIcon.png",
                      alt: "line分享",
                      title: "line分享",
                    },
                  }),
                  _vm._v("分享"),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "fbShare shareBtn",
              class:
                _vm.usePage === "giftContentAnniversary" && !_vm.collapseOpen
                  ? "d-none"
                  : "",
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.vipMediaShareUrl.fbLink,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://cdn2.wuwow.tw/pictures/socialites/fb-icon.png",
                      alt: "line分享",
                      title: "line分享",
                    },
                  }),
                  _vm._v("分享"),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.usePage === "leftSideBar",
              expression: "usePage === 'leftSideBar'",
            },
          ],
        },
        [_c("p", [_vm._v("我的推薦碼：" + _vm._s(_vm.mgmInfo.recommend_code))])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }