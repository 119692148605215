var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "menu-list wuwow-card",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-head text-center",
          on: {
            click: function ($event) {
              return _vm.switchMenuList()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("menu_list")))]
      ),
      _c(
        "transition",
        {
          attrs: {
            name: "fade",
          },
        },
        [
          !_vm.showMenuList
            ? _c(
                "b-list-group",
                [
                  _c(
                    "b-list-group-item",
                    {
                      attrs: {
                        href: "#",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.gotoTop()
                        },
                      },
                    },
                    [_vm._v("WuShare首堂課程(B)")]
                  ),
                  _c(
                    "b-list-group-item",
                    {
                      attrs: {
                        href: "#selfPurchaseTitlePosition",
                      },
                    },
                    [_vm._v("學員專屬自購優惠(B)")]
                  ),
                  _c(
                    "b-list-group-item",
                    {
                      attrs: {
                        href: "#additionalPrchaseTitlePosition",
                      },
                    },
                    [_vm._v("學員專屬堂數加價購(B)")]
                  ),
                  _c(
                    "b-list-group-item",
                    {
                      attrs: {
                        href: "#recommendTitlePosition",
                      },
                    },
                    [_vm._v("8小時邏輯速成班推薦好友獎勵活動!(B)")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }